import cn from "classnames";
import { Form, FormikHelpers, FormikProps } from "formik";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse from "html-react-parser";
import { useState } from "react";
import * as Yup from "yup";

import Button from "../../../atoms/button/Button";
import PageStepAnimation from "../../../atoms/page-step-animation/PageStepAnimation";
import TextFormControl from "../../../atoms/text-form-control/TextFormControl";
import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import PageStepForm from "../../../organisms/page-step-form/PageStepForm";
import { Result } from "../../../organisms/result/domain/result";
import { useResult, useUpdateResult } from "../../../organisms/result/use-cases/result-use-cases";
import { PageId } from "../../../settings/pages";
import { Events, track } from "../../../utils/analytics";
import { handleUpdateResultErrors } from "../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../utils/hooks/useTrackPageViewed";
import { rollbar } from "../../../utils/rollbar";
import * as styles from "../../Chip.module.scss";

interface Image {
  default: string;
}

const chipSVG: Image = require("../../../images/pages/results/chip/chip.svg") as Image;

const chipSVGSrc: string = chipSVG.default;

interface ChipFormValues {
  chip: string;
  chip_confirmation: string;
}

const Page = () => {
  const { t } = useTranslation();
  const result = useResult() as Result;
  const updateResult = useUpdateResult();
  const nextUrl = `/results/${result?.uuid}/pet_family/`;
  const [hasDuplicatedChipErrorMessage, setHasDuplicatedChipErrorMessage] = useState(false);

  useTrackPageViewed(Events.CHIP_VIEWED_BROWSER);

  const handleFormSubmission = (
    values: ChipFormValues,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    if (!updateResult) {
      return;
    }

    const petChip = values.chip;

    track("chip_cta_click", { traits: { petChip } });

    updateResult({ pet_chip: petChip })
      .then((updatedResult) => {
        if (updatedResult.hasHealthPurchased) {
          setSubmitting(false);
          setHasDuplicatedChipErrorMessage(true);

          track(Events.ERROR_OCCURRED, {
            error: "Submit attempt with duplicated chip",
            petChip,
          });
          rollbar.warn("Submit attempt with duplicate chip", {
            fingerprint: "duplicated-chip",
            petChip,
          });

          return;
        }

        void navigate(nextUrl);
      })
      .catch((err: Error) => {
        handleUpdateResultErrors(err, "chip");
        alert(t("landing.error_message"));
        setSubmitting(false);
      });
  };

  const chipFormData = {
    initialValues: {
      chip: result?.pet_chip || "",
      chip_confirmation: "",
    },
    validationSchema: Yup.object({
      chip: Yup.string()
        .trim()
        .length(15, t("common.validation.length", { length: 15 }))
        .required(t("common.validation.required")),
      chip_confirmation: Yup.string()
        .trim()
        .equals([Yup.ref("chip"), null], t("common.validation.match"))
        .required(t("common.validation.required")),
    }),
    handleSubmit: (values: ChipFormValues, { setSubmitting }: FormikHelpers<ChipFormValues>) => {
      handleFormSubmission(values, setSubmitting);
    },
    children: (props: FormikProps<ChipFormValues>) => {
      const { isSubmitting, setFieldValue, isValid, dirty } = props;

      return (
        <Form className={cn(styles.chipForm)}>
          <TextFormControl
            name="chip"
            label={t("chip.field.title")}
            type="tel"
            maxLength={15}
            onChange={(e) => setFieldValue("chip", e.target.value.replace(/\D/g, ""))}
          />
          <TextFormControl
            name="chip_confirmation"
            label={t("chip.chip_confirmation.field.title")}
            type="tel"
            maxLength={15}
            onChange={(e) => setFieldValue("chip_confirmation", e.target.value.replace(/\D/g, ""))}
          />
          {hasDuplicatedChipErrorMessage && (
            <p className={cn(styles.duplicatedChipErrorMessage)}>
              {parse(t("chip.duplicate_chip.title"))}
            </p>
          )}
          <Button
            type="submit"
            disabled={
              isSubmitting ||
              !(isValid && dirty) ||
              props.values.chip !== props.values.chip_confirmation
            }
            isLoading={isSubmitting}
          >
            {t("chip.cta")}
          </Button>
        </Form>
      );
    },
  };

  return (
    <>
      {result && (
        <PageStepAnimation>
          <PageStepForm
            title={t("pg_chip.title", { petName: result.pet_name })}
            subtitle={t("pg_chip.subtitle")}
            image={chipSVGSrc}
            formData={chipFormData}
          />
        </PageStepAnimation>
      )}
    </>
  );
};

export default withFunnelWrapper(Page, "pg-chip", PageId.chip);

export const query = graphql`
  query ChipRequestQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
